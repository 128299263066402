<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="4"
          class="mb-2"
        >
          <h3>Account number or email:</h3>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Search by account number or email"
                @keyup.enter="advancedFilter.search = search"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="clearSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  :disabled="!search"
                  @click="advancedFilter.search = search"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>Account tags:</h3>
          <tag-tree-select v-model="advancedFilter.accountTags" />

          <h3 class="mt-1">
            User tags:
          </h3>
          <tag-tree-select
            v-model="advancedFilter.userTags"
          />
        </b-col>
        <b-col
          lg="2"
          class="mb-2"
        >
          <h3>States:</h3>
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="advancedFilter.state"
            name="flavour-1"
            class="d-flex flex-wrap"
          >
            <b-badge
              v-for="(value, key, index) in withdrawalStateVariants"
              :key="index"
              :variant="`light-${value}`"
              class="mr-1 mt-1"
            >
              <b-form-checkbox
                :value="key"
                class="vh-center"
              >
                <span :class="`text-${value}`">{{ key }}</span>
              </b-form-checkbox>
            </b-badge>
          </b-form-checkbox-group>
        </b-col>
        <b-col
          lg="4"
          class="my-2 text-right"
        >
          <b-button
            variant="primary"
            @click.prevent="onCreateWithdrawal"
          >
            Create withdrawal
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <data-table
      :module-name="'withdrawalsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="withdrawal_datetime"
      :sort-desc="true"
      :actions="actions"
      deletable
      @edit="onEdit"
      @confirm="onConfirm"
      @removeCertificate="onRemoveCertificate"
      @stateInvestigationContacted="onInvestigationContacted"
      @stateInvestigationDeclined="onInvestigationDeclined"
      @editComment="onEditComment"
      @invoice="onInvoice"
    >
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              <span :class="['completed', 'approved by support'].includes(data.item.state) ? '' : 'text-danger'">{{ data.item.user.firstName }} {{ data.item.user.lastName }}</span>
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              ({{ data.item.user.country.name }})
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
            <b-button
              v-b-tooltip.hover.right="'Show risk score summary for user'"
              size="sm"
              class="m-50"
              variant="outline-warning"
              @click="onRsSummary(data.item)"
            >
              RSS
            </b-button>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account.account_id } }">
              {{ data.item.account.account_id }}
            </b-link>
            <risk-score-badge
              v-if="data.item.account.risk_score"
              class="mx-1"
              :score-results="data.item.account.risk_score_results"
              :score="data.item.account.risk_score"
            />
            <b-link
              :to="{ name: 'tools-risk-score', params: { id: data.item.account.account_id } }"
              target="_blank"
            >
              <b-button
                v-b-tooltip.hover.top="'Open risk score analysis'"
                class="m-50"
                variant="outline-primary"
                size="sm"
              >
                RS
              </b-button>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.account.tags"
              :extras-tags="getAccountExtraTags(data.item.account)"
            />
            <span v-if="data.item.account.profit_split === 90">UNFAZED</span>
          </b-col>
        </b-row>
      </template>

      <template #cell(current_balance)="data">
        <span v-if="!data.item.completed">{{ formatCurrency(data.item.account.balance - data.item.account.deposit, data.item.account.currency.label) }}</span>
        <span v-else>Withdrawal completed</span>
      </template>

      <template #cell(captured_balance)="data">
        {{ formatCurrency(data.item.amount, data.item.account.currency.label) }}
      </template>

      <template #cell(amount)="data">
        {{ formatCurrency(data.item.profit_share_amount, data.item.account.currency.label) }}
        <b-badge
          v-if="data.item.account.withdrawal_count === 0 || (data.item.account.withdrawal_count === 1 && data.item.withdrawal_count === 1)"
          variant="light-primary"
        >
          {{ getRootAccount(data.item.account, data.item.amount, data.item.profit_share, data.item.account.currency.label) }}
        </b-badge>
      </template>

      <template #cell(profit_share)="data">
        {{ data.item.profit_share }}%
      </template>

      <template #cell(withdrawal_datetime)="data">
        {{ formatDateTime(data.item.withdrawal_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge
          :variant="`light-${withdrawalStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(confirmed_at)="data">
        {{ formatDateTime(data.item.confirmed_at) }}
      </template>

      <template #cell(stateAction)="data">
        <b-button
          v-if="!data.item.confirmed_at"
          v-b-tooltip.hover.left="'Approve withdrawal to payout'"
          variant="outline-success"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'approved by support')"
        >
          <feather-icon icon="UserCheckIcon" />
        </b-button>
        <b-button
          v-if="!data.item.confirmed_at"
          v-b-tooltip.hover.left="'Change state to INVESTIGATION'"
          variant="outline-warning"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'investigation')"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </template>
    </data-table>
    <b-modal
      id="withdrawalModal"
      :title="title"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <withdrawal-form
        :withdrawal="withdrawalEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
    <b-modal
      id="withdrawalCommentModal"
      :title="title"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <withdrawal-comment-form
        :withdrawal="withdrawalEdit"
        @submit="onEditCommentSubmit"
      />
    </b-modal>
    <b-modal
      id="rsSummaryModal"
      title="Risk score summary"
      size="xl"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-linkage-summary-table :advanced-filter="rsSummaryFilter" />
    </b-modal>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import {
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCol,
  BRow,
  BBadge,
  BCard,
  BLink,
  BModal,
  VBTooltip, BFormCheckbox, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { isEmpty } from 'lodash'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { ACCOUNTS_SHORT, ACCOUNT_PHASES } from '@/enums/accounts'
import { STATE_VARIANTS } from '@/enums/withdrawals'
import DataTable from '@/components/ui/tables/DataTable.vue'
import WithdrawalForm from '@/views/withdrawal/components/WithdrawalForm.vue'
import WithdrawalAPI from '@/api/withdrawal'
import TagsList from '@/components/page/TagsList.vue'
import TagTreeSelect from '@/components/ui/tables/TagTreeSelect.vue'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import WithdrawalCommentForm from '@/views/withdrawal/components/WithdrawalCommentForm.vue'
import AccountLinkageSummaryTable from '@/components/entity/accountLinkageSummary/AccountLinkageSummaryTable.vue'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Confirm',
    icon: 'CheckCircleIcon',
    emit: 'confirm',
  },
  {
    text: 'Remove certificate',
    icon: 'TrashIcon',
    emit: 'removeCertificate',
  },
  {
    text: 'Investigation - CONTACTED',
    icon: 'XIcon',
    emit: 'stateInvestigationContacted',
  },
  {
    text: 'Investigation - DECLINED',
    icon: 'XIcon',
    emit: 'stateInvestigationDeclined',
  },
  {
    text: 'Comment',
    icon: 'EditIcon',
    emit: 'editComment',
  },
  {
    text: 'Invoice',
    icon: 'FileIcon',
    emit: 'invoice',
  },
]

const fields = [
  {
    key: 'user',
    sortable: false,
  },
  {
    key: 'account',
    sortable: false,
  },
  {
    key: 'current_balance',
    label: 'Current balance on account',
    sortable: true,
  },
  {
    key: 'captured_balance',
    label: 'Captured balance',
    sortable: true,
  },
  {
    key: 'amount',
    label: 'Profit share $',
    sortable: true,
  },
  {
    key: 'profit_share',
    label: 'Profit share %',
    sortable: true,
  },
  {
    key: 'withdrawal_datetime',
    sortable: true,
  },
  {
    key: 'state',
    sortable: false,
  },
  {
    key: 'platform',
    sortable: false,
  },
  {
    key: 'confirmed_at',
    sortable: true,
  },
  {
    key: 'createdAt',
    sortable: true,
  },
  {
    key: 'stateAction',
    sortable: false,
  },
]

export default {
  components: {
    AccountLinkageSummaryTable,
    WithdrawalCommentForm,
    BFormCheckboxGroup,
    BFormCheckbox,
    TagTreeSelect,
    WithdrawalForm,
    DataTable,
    TagsList,
    RiskScoreBadge,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCol,
    BRow,
    BBadge,
    BCard,
    BLink,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      withdrawalStateVariants: STATE_VARIANTS,
      fields,
      advancedFilter: {
        search: '',
        state: [],
      },
      search: '',
      actions,
      title: 'Create withdrawal',
      withdrawalEdit: null,
      userTags: [],
      accountTags: [],
      rsSummaryFilter: { search: '', user_id: null, country: null },
    }
  },
  computed: {
    ...get('withdrawalsList', ['filters']),
  },
  beforeMount() {
    if (!isEmpty(this.filters)) {
      this.advancedFilter = { ...this.filters }
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    getRootAccount(account, amount, profitSplit, currency) {
      if (account.parent === null) {
        const createdAt = new Date(account.createdAt)
        const dateToRefundableFee = new Date('2024-02-16T19:00:00+00:00')
        if (createdAt < dateToRefundableFee) return account.order === null ? '+ Original order fee not found' : `+ order fee ${formatCurrency(account.order.price, 'USD')}`

        return `+ 5% profit split. Total ${formatCurrency(amount * ((profitSplit + 5) / 100), currency)}`
      }
      return this.getRootAccount(account.parent, amount, profitSplit, currency)
    },
    onEdit(item) {
      this.title = 'Edit withdrawal'
      this.withdrawalEdit = item
      this.$bvModal.show('withdrawalModal')
    },
    onEditComment(item) {
      this.title = 'Edit Comment'
      this.withdrawalEdit = item
      this.$bvModal.show('withdrawalCommentModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('withdrawalModal')
      this.withdrawalEdit = null
    },
    onEditCommentSubmit() {
      this.$bvModal.hide('withdrawalCommentModal')
      this.withdrawalEdit = null
    },
    onCreateWithdrawal() {
      this.title = 'Create withdrawal'
      this.withdrawalEdit = null
      this.$bvModal.show('withdrawalModal')
    },
    onConfirm(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal will be approved!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.confirmWithdrawal(item.id).then(() => {
            this.$store.dispatch('withdrawalsList/reloadAll')
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Filed',
                icon: 'XCircleIcon',
                text: err.data.detail,
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    onInvestigationContacted(item) {
      WithdrawalAPI.changeWithdrawalState(item.id, 'investigation - contacted').then(() => {
        this.$store.dispatch('withdrawalsList/reloadAll')
      })
    },
    onInvestigationDeclined(item) {
      WithdrawalAPI.changeWithdrawalState(item.id, 'investigation - declined').then(() => {
        this.$store.dispatch('withdrawalsList/reloadAll')
      })
    },
    onChangeState(item, state) {
      WithdrawalAPI.changeWithdrawalState(item.id, state).then(() => {
        this.$store.dispatch('withdrawalsList/reloadAll')
      })
    },
    onRsSummary(item) {
      this.rsSummaryFilter.user_id = item.user.id
      this.rsSummaryFilter.country = item.user.country.id
      this.$bvModal.show('rsSummaryModal')
    },
    onRemoveCertificate(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal certificate will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          WithdrawalAPI.removeWithdrawalCertificate(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Certificate was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    onInvoice(item) {
      WithdrawalAPI.getWithdrawalInvoice(item.id, item.user.email)
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
